var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1080",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "height": "100%"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" 공간 불러오기 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px)",
      "overflow-y": "auto"
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('rental-subject-list-search', {
          staticClass: "mb-n3",
          attrs: {
            "mode": "model",
            "flat": "",
            "showsSearch": "",
            "dense": ""
          },
          on: {
            "input": function ($event) {
              _vm.page = 1;
            }
          },
          model: {
            value: _vm.query,
            callback: function ($$v) {
              _vm.query = $$v;
            },
            expression: "query"
          }
        }), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.thumb`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('image-popup', {
          attrs: {
            "src": value === null || value === void 0 ? void 0 : value.url,
            "rounded": "",
            "outlined": ""
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.emit(item);
            }
          }
        }, 'v-btn', {
          loading: _vm.loading
        }, false), [_vm._v(" " + _vm._s(_vm.isCreate ? "전체 불러오기" : "시간 불러오기") + " ")])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 20,
            "color": "primary"
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }