<template>
    <v-dialog v-model="shows" max-width="1080" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }" height="100%">
            <v-card-title>
                공간 불러오기
                <v-spacer />
                <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 16px - 32px - 10px - 1px - 1px - 60px); overflow-y: auto">
                <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template #top>
                        <rental-subject-list-search v-model="query" mode="model" flat showsSearch dense class="mb-n3" @input="page = 1" />
                        <v-divider />
                    </template>

                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                    <template #[`item.thumb`]="{ value }">
                        <image-popup :src="value?.url" rounded outlined />
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-btn color="primary" v-bind="{ loading }" @click="emit(item)"> {{ isCreate ? "전체 불러오기" : "시간 불러오기" }} </v-btn>
                    </template>
                    <template #footer>
                        <v-divider />
                        <v-pagination v-model="page" :length="pageCount" :total-visible="20" color="primary" class="my-2" />
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { initRentalSubject, RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import RentalSubjectListSearch from "../list/rental-subject-list-search.vue";

const headers = [
    { text: "유형", value: "type", formatter: (key) => RENTAL_SUBJECT_TYPES[key]?.text, width: +60 },
    { text: "썸네일", value: "thumb", class: "pa-0", cellClass: "pa-1", align: "center", width: +60 },
    { text: "공간명", value: "name" },
    // { text: "코드명", value: "code" },
    { text: "위치", value: "location", width: 120, align: "center" },
    { text: "수용인원", value: "capacity", width: 120, align: "center" },
    { text: "순서", value: "index", width: +60, align: "center" },
    { text: "", value: "actions", width: 128 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "left", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ImagePopup,
        RentalSubjectListSearch,
    },
    props: {
        value: { type: Object, default: initRentalSubject },
    },
    data: () => ({
        subjects: [],

        query: undefined,

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        headers,

        shows: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        items() {
            return [...this.subjects];
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.query || {};

            return { ...query };
        },
    },
    watch: {
        shows() {
            if (this.shows) this.search();
        },
        page() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, subjects } = await api.console.rental.subjects.gets({
                    headers: { skip, limit },
                    params,
                });

                this.subjects = subjects;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async emit(item) {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    item = initRentalSubject(item);

                    delete item._id;
                    delete item.createdAt;

                    delete item._thumb;
                    if (item.thumb) item.thumb = item.thumb instanceof File ? item.thumb : await api.getResource(item.thumb.url);

                    delete item._photos;
                    delete item.photos;
                } else {
                    item = initRentalSubject({
                        ...(this.value || {}),
                        rental: {
                            ...(item?.rental || {}),

                            holidays: [...(this.value?.rental?.holidays || [])],
                            isActive: this.value?.rental?.isActive ?? false,
                        },
                    });
                }

                this.$emit("input", item);
                this.shows = false;
                alert("해당 공간를 불러왔습니다");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
