<template>
    <v-dialog v-model="shows" max-width="85vh" width="85%" :disabled="!src" content-class="black">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
            <template v-if="!$scopedSlots['activator']">
                <v-img
                    :src="src"
                    :style="!src ? 'cursor:initial' : ''"
                    :class="{
                        'v-sheet--outlined': outlined,
                        [`rounded${typeof rounded == 'string' ? `-${rounded}` : ''}`]: !!rounded,
                    }"
                    v-bind="{ ...attrs, aspectRatio, ...$attrs }"
                    v-on="on"
                    @click.prevent.stop
                >
                    <template #placeholder>
                        <v-overlay absolute light color="transparent">
                            <v-icon color="grey">mdi-image-broken-variant</v-icon>
                        </v-overlay>
                    </template>
                </v-img>
            </template>
        </template>

        <template v-if="src">
            <v-card :href="src" target="_blank">
                <v-img :src="src" v-bind="{ aspectRatio, ...$attrs }" max-height="85vh" contain>
                    <template #placeholder>
                        <v-overlay absolute light color="transparent">
                            <v-icon color="grey">mdi-image-broken-variant</v-icon>
                        </v-overlay>
                    </template>

                    <v-btn absolute right top icon tile dark x-large @click.prevent.stop.capture="shows = false" style="background-color: rgba(0, 0, 0, 0.3)">
                        <v-icon size="4rem">mdi-close</v-icon>
                    </v-btn>
                </v-img>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    props: {
        src: { type: String, default: undefined },
        rounded: { type: [Boolean, String], default: false },
        outlined: { type: Boolean, default: false },
        aspectRatio: { type: [Number, String], default: 1 / 1 },
    },
    data: () => ({
        shows: false,
    }),
};
</script>

<style></style>
