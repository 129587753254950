<template>
    <list-search v-bind="{ ...$attrs, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="4" lg="3" class="pa-2">
                <autocomplete-rental-subject label="공간명" v-model="query['_subject']" placeholder="전체" clearable v-bind="inputAttrs" @input="query['_program'] = null" />
            </v-col>
            <v-col cols="12" sm="4" lg="3" class="pa-2">
                <autocomplete-user label="회원" v-model="query['_user']" placeholder="전체" clearable v-bind="inputAttrs" v-on="{ emit }" />
            </v-col>
            <v-col cols="12" sm="4" lg="3" class="pa-2">
                <v-select label="상태" v-model="query.state" :items="states" placeholder="전체" clearable v-bind="inputAttrs" />
            </v-col>

            <v-col cols="12" class="pa-2">
                <v-row align="center" class="ma-n2">
                    <v-col cols="12" sm="6" lg="3" class="pa-2">
                        <v-text-field label="기간검색(시작일)" v-model="query['searchDateValue'][0]" type="date" max="2999-12-31" v-bind="inputAttrs" />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3" class="pa-2">
                        <v-text-field label="기간검색(종료일)" v-model="query['searchDateValue'][1]" type="date" max="2999-12-31" v-bind="inputAttrs" />
                    </v-col>
                    <v-col class="pa-2" style="overflow-x: auto">
                        <dates-toggle v-model="query.searchDateValue" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { inputAttrs, RENTAL_SCHEDULE_STATES, RENTAL_SUBJECT_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

import DatesToggle from "@/components/console/dumb/dates-toggle.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteRentalSubject from "@/components/dumb/autocomplete-rental-subject.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["_user"]: query["_user"] ?? null,

    ["_subject"]: query["_subject"] ?? null,

    ["state"]: query["state"] ?? null,

    ["searchDateValue"]: [...(query["searchDateValue"] ?? [null, null])],
});

let states = Object.values(RENTAL_SCHEDULE_STATES);

export default {
    components: {
        ListSearch,

        DatesToggle,
        AutocompleteUser,
        AutocompleteRentalSubject,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        states,

        inputAttrs,
        RENTAL_SUBJECT_TYPES,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (!query["_user"]) delete query["_user"];

            if (!query["_subject"]) delete query["_subject"];

            if (!query["state"]) delete query["state"];

            if (!query["searchDateValue"]?.[0] && !query["searchDateValue"]?.[1]) {
                delete query["searchDateValue"];
            }

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
