<template>
    <v-dialog v-model="shows" width="700px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }">
            <v-card-title>
                <list-heading title="금산마을 지역 리스트">
                    <template #add-button>
                        <story-region-form>
                            <template #activator="{ attrs, on }">
                                <v-btn text icon tile color="primary" title="공지사항 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                            </template>
                        </story-region-form>
                    </template>
                </list-heading>
                <v-spacer />
                <v-row no-gutters>
                    <v-col cols="10" class="pa-0">
                        <v-select v-model="filter.parent" :items="regionItems" v-bind="{ ...attrs_input }" @input="selectSearch" />
                    </v-col>
                    <v-col cols="2" class="pa-0">
                        <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.index`]="{ item }">
                        <story-regione-index-edit v-bind="{ value: item }" @update="update">
                            {{ item?.index }}
                        </story-regione-index-edit>
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <story-region-form :value="item" @input="search">
                            <template #activator="{ attrs, on }">
                                <v-btn small text icon v-bind="attrs" v-on="on">
                                    <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                            </template>
                        </story-region-form>

                        <v-btn small text icon @click="remove(item)">
                            <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                    </template>
                    <template #footer>
                        <v-divider />
                        <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(value) => (page = value)"></v-pagination> </template
                ></v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { STORY_REGION_TYPES, attrs_input } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import StoryRegionForm from "@/components/console/story/story-region-form.vue";
import StoryRegioneIndexEdit from "@/components/console/story/story-region-index-edit.vue";
export default {
    components: {
        ListHeading,
        StoryRegionForm,
        StoryRegioneIndexEdit,
    },
    data: () => ({
        shows: false,
        loading: false,
        attrs_input,

        filter: { parent: null },
        regionItems: [{ text: "전체", value: null }, ...Object.values(STORY_REGION_TYPES)],
        regions: [],
        summary: { totalCount: 0 },
        limit: 15,
        page: 1,
    }),
    computed: {
        items() {
            return [...this.regions];
        },
        headers() {
            return [
                { text: "#", value: "index", width: 40 },
                { text: "읍/면", value: "parent", width: 80, formatter: (value) => STORY_REGION_TYPES[value].text },
                { text: "지역명", value: "name", width: 120 },
                { text: "Actions", value: "actions", width: 10 },
            ];
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
    },
    async mounted() {
        await this.search();
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                let { skip, limit } = this;

                let { summary, regions } = await api.console.story.region.gets({
                    headers: { skip, limit },
                    params: this.filter,
                });

                this.regions = regions;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        async update(item) {
            if (this.loading) return;
            this.loading = true;
            try {
                await api.console.story.region.put(item);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
                await this.search();
            }
        },
        async remove(item) {
            if (this.loading) return;
            this.loading = true;
            try {
                if (confirm("해당 지역을 삭제하시겠습니까?")) {
                    await api.console.story.region.delete(item);

                    alert("삭제되었습니다.");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
                await this.search();
            }
        },
        selectSearch() {
            this.page = 1;
            this.search();
        }
    },
    watch: {
        page() {
            this.search();
        },
    },
};
</script>

<style>
</style>