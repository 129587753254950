<template>
    <v-date-picker v-model="date" no-title v-bind="{ ...$attrs, dayFormat, allowedDates }" @update:picker-date="changeMonth" />
</template>

<script>
import dayjs from "@/plugins/vue-dayjs";

import { initRentalSubject } from "@/assets/variables";

export default {
    props: {
        value: { type: String, default: dayjs().add(1, "day").format("YYYY-MM-DD") },
        subject: { type: Object, default: initRentalSubject },
    },
    data: () => ({
        date: dayjs().add(1, "day").format("YYYY-MM-DD"),
    }),
    computed: {
        businessDays() {
            return this.subject.rental.businessDays;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        date() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.date = this.value || this.$dayjs().add(1, "day").format("YYYY-MM-DD");
        },

        emit() {
            this.$emit("input", this.date);
        },

        changeMonth(date) {
            let month = date.split("-")[1];
            let today = this.$dayjs().format("YYYY-MM-DD");
            if (month == today.split("-")[1]) {
                this.date = today;
            } else {
                this.date = this.$dayjs(date).startOf("month").format("YYYY-MM-DD");
            }
        },

        dayFormat(date) {
            return this.$dayjs(date).get("date");
        },

        allowedDates(date) {
            const isAfterStart = !this.$dayjs(date).isBefore(this.$dayjs().add(1, "day"), "date");
            const isBeforeEnd = this.$dayjs().add(1, "month").add(1, "days").isAfter(date, "date");
            const isBusinessDay = this.businessDays.includes(this.$dayjs(date).day());
            return isAfterStart && isBeforeEnd && isBusinessDay;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-date-picker-header__value {
        font-size: 18px !important;
    }
}
@media (min-width: 768px) {
    ::v-deep {
        .v-date-picker-header__value {
            font-size: 24px !important;
        }
    }
}
</style>