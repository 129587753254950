<template>
    <v-layout justify-center>
        <v-responsive width="100%" max-width="1080" height="100%" class="mx-n3 px-3">
            <list-heading v-model="showsSearch" title="공간 목록" showsFilterButton>
                <template #add-button>
                    <rental-subject-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" title="공간 생성" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </rental-subject-form>
                </template>
            </list-heading>

            <rental-subject-list-search v-bind="{ showsSearch }" outlined flat dense />

            <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="overflow: hidden">
                <!-- <template #top>
                    <v-row no-gutters align="center">
                        <v-col cols="auto"> <v-btn icon text width="59" class="pa-0 caption text--secondary" style="pointer-events: none"> 공간유형 </v-btn> </v-col>
                        <v-col cols="auto" align-self="stretch"> <v-divider vertical /> </v-col>
                        <v-col> <rental-subject-list-types /> </v-col>
                    </v-row>
                    <v-divider />
                    <v-expand-transition>
                        <div v-show="type == RENTAL_SUBJECT_TYPES.EQUIPMENTS.value">
                            <v-row no-gutters align="center">
                                <v-col cols="auto"> <v-btn icon text width="59" class="caption text--secondary" style="pointer-events: none"> 사용장소 </v-btn> </v-col>
                                <v-col cols="auto" align-self="stretch"> <v-divider vertical /> </v-col>
                                <v-col> <rental-subject-list-places /> </v-col>
                            </v-row>
                            <v-divider />
                        </div>
                    </v-expand-transition>
                </template> -->

                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.photos`]="{ value }">
                    <image-popup :src="value?.[0]?.url" rounded outlined />
                </template>

                <template #[`item.rental.isActive`]="{ item }">
                    <v-switch v-model="item.rental.isActive" class="d-inline-block mr-n4" v-bind="switchAttrs" @change="(isActive) => update({ _id: item._id, rental: { ...item.rental, isActive } })" />
                </template>

                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" class="d-inline-block mr-n4" v-bind="switchAttrs" @change="(shows) => update({ _id: item._id, shows })" />
                </template>

                <template #[`item.index`]="{ item, value }">
                    <edit-index :value="item" v-on="{ update }">
                        {{ value?.format?.() || "-" }}
                    </edit-index>
                </template>

                <template #[`item.actions`]="{ item }">
                    <rental-subject-form :value="item" @input="(item) => update(item)">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </rental-subject-form>

                    <v-btn small text icon @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="20" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { RENTAL_SUBJECT_TYPES, switchAttrs } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

import EditIndex from "@/components/console/dumb/edit/edit-index.vue";
import RentalSubjectForm from "@/components/console/rental/subjects/form/rental-subject-form.vue";
import RentalSubjectListTypes from "@/components/console/rental/subjects/list/rental-subject-list-types.vue";
import RentalSubjectListSearch from "@/components/console/rental/subjects/list/rental-subject-list-search.vue";
import RentalSubjectListPlaces from "@/components/console/rental/subjects/list/rental-subject-list-places.vue";

const headers = [
    { width: +60, text: "유형", value: "type", formatter: (key) => RENTAL_SUBJECT_TYPES[key]?.text },
    { width: +60, text: "이미지", value: "photos", class: "pa-0", cellClass: "pa-1", align: "center" },
    { text: "공간명", value: "name" },
    // { width: 475, text: "영문명", value: "code" },
    { width: 120, text: "위치", value: "location", align: "center" },
    { width: 120, text: "수용인원", value: "capacity", align: "center" },
    { width: 120, text: "면적", value: "dimension", align: "center" },
    { width: +90, text: "예약가부", value: "rental.isActive", align: "center" },
    { width: +90, text: "노출여부", value: "shows", align: "center" },
    { width: +60, text: "순서", value: "index", align: "center" },
    { width: +88, text: "", value: "actions", align: "center" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "left", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ImagePopup,
        ListHeading,

        EditIndex,
        RentalSubjectForm,
        RentalSubjectListTypes,
        RentalSubjectListSearch,
        RentalSubjectListPlaces,
    },

    props: {
        type: { type: String, default: RENTAL_SUBJECT_TYPES.FACILITIES.value },
    },

    data: () => ({
        subjects: [],
        summary: { totalCount: 0 },

        limit: 10,

        headers,
        switchAttrs,

        RENTAL_SUBJECT_TYPES,

        loading: false,
        showsSearch: true,
    }),

    computed: {
        items() {
            return [...this.subjects];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.type = this.type;
            if (!query.type) delete query.type;

            return { ...query };
        },
    },

    mounted() {
        this.init();
    },

    watch: {
        params() {
            this.search();
        },
    },

    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;

                var { summary, subjects } = await api.console.rental.subjects.gets({
                    headers: { skip, limit },
                    params,
                });

                this.subjects = subjects;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async updateItem(item) {
            const index = this.subjects.findIndex(({ _id }) => _id == item._id);
            this.subjects.splice(index, 1, item);
            this.search();
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { subject } = await api.console.rental.subjects.put(item);
                this.updateItem(subject);
                this.loading = false;
                this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async remove(item) {
            if (this.loading) return;
            else {
                const go = confirm(`해당 설비를 삭제하시겠습니까?\n설비명: ${item?.name}\n영문명: ${item.code}`);
                if (go) {
                    this.loading = true;
                    try {
                        const { subject } = await api.console.rental.subjects.delete(item);
                        this.subjects = this.subjects.filter(({ _id }) => _id !== subject._id);
                        this.summary.totalCount -= 1;
                        alert(`해당 예약금지이 삭제되었습니다.`);
                        this.loading = false;
                        await this.search();
                    } finally {
                        if (this.loading) this.loading = false;
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }

    .v-small-dialog__activator {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
